import React from "react";
import { Link } from "react-router-dom";
import styles from "./blogpost.module.css";

// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const Pentesting_Basics = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <div className={styles.containerblog}>
        <div className={styles.con}>
          <button
            onClick={goBack}
            style={{
              background: "none",
              border: "none",
              padding: "0",
              outline: "inherit",
              cursor: "pointer",
              color: "inherit",
            }}
          >
            <p className={styles.headingf}>back to</p>

            <p className={styles.headingt}>Blog</p>
          </button>

          <div className={styles.links}>
            <p>
              ! Links :<Link to="/">Home</Link> |{" "}
              <Link to="/projects">Blog</Link> | <Link to="/about">Fox</Link>
            </p>
          </div>

          <div className={styles.headingblog}>
            <h1>Common Terms In Pentesting</h1>
          </div>
          <div className={styles.headingdet}>
            <h6>22/9/2023</h6>
            <h6>writer: bl4ckf0xk</h6>
          </div>
          <hr></hr>
          <div className={styles.blogbody}>
            <h1 className={styles.headingblog}>Shell</h1>
            <p className={styles.subheaddes}># On a linux system</p>
            <p className={styles.subheaddes}>
              shell is a program that takes input from the user via the keyboard
              and passes these commands to the operating system to perform the
              specific funtion.{" "}
            </p>
            <p className={styles.subheaddes}>
              Most Linux systems use a proogram called Bash (Bourne Again Shell)
              as a shell program to interact with the operating system.
            </p>

            <div className={styles.section}>
              <h2 className={styles.subheading}>"Getting a shell" </h2>
              <p className={styles.subheaddes}>
                This means that the target host has been exploted, and we have
                obtained shell-level access and can run commands interactively
                as if we are sitting logged in to the host
              </p>

              <h1 className={styles.subheading}>Shell Types</h1>
              <h4>
                &#62; Reverse Shell{" "}
                <span style={{ fontSize: 17, color: "darkorchid" }}>
                  &#91;Initiate a connection back to a "listner"&#93;
                </span>
              </h4>
              <h4>
                &#62; Bind Shell{" "}
                <span style={{ fontSize: 17, color: "darkorchid" }}>
                  &#91;"Bind" to a specific port on the target host and wait for
                  a connection&#93;
                </span>
              </h4>
              <h4>
                &#62; Web Shell{" "}
                <span style={{ fontSize: 17, color: "darkorchid" }}>
                  &#91;Runs OS commands via the web browser. (Semi
                  interactive)&#93;
                </span>
              </h4>

              <h1 className={styles.headingblog}>Port</h1>
              <p className={styles.subheaddes}>
                A Port can be thought of as a window or door on a house, if a
                window or door is left open or not locked correctly, we can
                often gain unauthorized access to home.
              </p>
              <p className={styles.subheaddes}>
                Ports are associated with a specific process or service. Each
                port is assigned a number, and many are standardized across all
                network connected devices.
              </p>

              <h1>There are Two types of ports</h1>
              <h4>&#62; Transmission Control Protocol (TCP)</h4>
              <h4>&#62; User Datagram Protocol (UDP)</h4>
            </div>
            <div className={styles.section}>
              <h2 className={styles.subheading}># TCP</h2>
              <p className={styles.subheaddes}>
                {" "}
                - Connection between a client and a server must be established
                before data can be sent.
              </p>

              <h2 className={styles.subheading}># UDP</h2>
              <p className={styles.subheaddes}>
                {" "}
                - There is no "handshake" and therefor introduces a certain
                amount of unrealiability. [Suitable for applications that run
                time-sensitive tasks]
              </p>

              <p className={styles.subheaddes}>
                Some of the most well-known{" "}
                <span style={{ fontSize: 17, color: "darkseagreen" }}>TCP</span>{" "}
                and{" "}
                <span style={{ fontSize: 17, color: "darkseagreen" }}>UDP</span>{" "}
                ports are listed below:
              </p>

              <p>
                <i>Port(s)</i>
              </p>
              <h4>
                &#62; 20/21 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;FTP&#93;
                </span>
              </h4>
              <h4>
                &#62; 22 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;SSH&#93;
                </span>
              </h4>
              <h4>
                &#62; 23 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;TELNET&#93;
                </span>
              </h4>
              <h4>
                &#62; 25 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;SMTP&#93;
                </span>
              </h4>
              <h4>
                &#62; 80 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;HTTP&#93;
                </span>
              </h4>
              <h4>
                &#62; 161 (TCP/UDP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;SNMP&#93;
                </span>
              </h4>
              <h4>
                &#62; 389 (TCP/UDP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;LDAP&#93;
                </span>
              </h4>
              <h4>
                &#62; 443 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;SSL/TLS(HTTPS)&#93;
                </span>
              </h4>
              <h4>
                &#62; 445 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;SMB&#93;
                </span>
              </h4>
              <h4>
                &#62; 3389 (TCP){" "}
                <span style={{ fontSize: 17, color: "darkgray" }}>
                  &#91;RDP&#93;
                </span>
              </h4>
            </div>
            <div className={styles.section}>
              <h1 className={styles.headingblog}>Web Server</h1>
              <p className={styles.subheaddes}>
                A web server is an application that runs on the back-end server,
                which handles all of the{" "}
                <span style={{ fontSize: 17, color: "darkseagreen" }}>
                  HTTP
                </span>{" "}
                traffic from the client-side browser, routes it to the requests
                destination pages, and finally responds to the client-side
                browser
              </p>
              <p className={styles.subheaddes}>
                Web application provide vast attack surface, making them
                high-value target for attckers and pentesters
              </p>
              <p className={styles.subheaddes}>
                There is a standerdized list of the top 10 web application
                vulnerabilities maintained by the Open Web Application Security
                Project{" "}
                <a
                  href="https://owasp.org/www-project-top-ten/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <span style={{ fontSize: 17, color: "red" }}>(OWASP) </span>
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pentesting_Basics;
