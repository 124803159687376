import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import ProjectDash from '../components/ProjectDash';

const Projects = () => {
  return (
    <>
      <Navbar />
      <ProjectDash />
      <Footer />
    </>
  )
}

export default Projects;