import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import About from '../components/About';

const AboutFunc = () => {
  return (
    <>
      <Navbar />
      <About />
      <Footer />
    </>
  )
}

export default AboutFunc
