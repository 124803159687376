import React from "react";
import { Link } from "react-router-dom";
import styles from "./blogpost.module.css";

import SyntaxHighlighter from "react-syntax-highlighter";
// import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useNavigate } from "react-router-dom";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const Bash = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <div className={styles.containerblog}>
        <div className={styles.con}>
          <button
            onClick={goBack}
            style={{
              background: "none",
              border: "none",
              padding: "0",
              outline: "inherit",
              cursor: "pointer",
              color: "inherit",
            }}
          >
            <p className={styles.headingf}>back to</p>

            <p className={styles.headingt}>Blog</p>
          </button>

          <div className={styles.links}>
            <p>
              ! Links :<Link to="/">Home</Link> |{" "}
              <Link to="/projects">Blog</Link> | <Link to="/about">Fox</Link>
            </p>
          </div>

          <div className={styles.headingblog}>
            <h1>Bash Shell</h1>
          </div>
          <div className={styles.headingdet}>
            <h6>23/9/2023</h6>
            <h6>writer: bl4ckf0xk</h6>
          </div>
          <hr></hr>
          <div className={styles.blogbody}>
            <h1 className={styles.headingblog}>Bourne Again Shell</h1>
            <p className={styles.subheaddes}>
              Some of the shells that Bash has borrowed concepts from the Bourne
              Shell (sh), The Korn Shell (ksh) and the C-shell (csh and it's
              successor, tcsh).
            </p>
            <h2 className={styles.subheading}># Key Points </h2>
            <p className={styles.subheaddes}>Shell Syntax</p>
            <p className={styles.subheaddes}>Shell Commands</p>

            <div className={styles.section}>
              <h1 className={styles.subheading}>Shell Syntax</h1>
              <p className={styles.subheaddes}>
                The shell reads its input and divides the input into words and
                operators, employing the quoting rules to select which meanings
                to assign varios words and characters
              </p>
              <h2 className={styles.subheading}># Shell Operations</h2>.
              <h4>
                - Reads input from a file / supplied as an argument to the "-C"
                invocation option / from the user's terminal
              </h4>
              <h4>- Breaks the input into words and operators</h4>
              <h4>- parses the token into simple and compound commands</h4>
              <h4>
                - Breaking the expanded token into lists of filenames and
                commands and arguments
              </h4>
              <h4>- Perform any necessary redirections</h4>
              <h4>- Execute the command</h4>
              <h4>- Wait for the command to complete and exit status</h4>
              <h2 className={styles.subheading}># Quoting</h2>
              <p className={styles.subheaddes}>
                {" "}
                Each of the shell metacharacters [ A character that, when
                unquoted, separates words. A metacharacter is a `space`, `tab`,
                `newline`, or one of the following characters: ]
                <span>
                  <SyntaxHighlighter>
                    ‘|’, ‘&’, ‘;’, ‘(’, ‘)’, &apos;&lt;&apos;, or
                    &apos;&gt;&apos;
                  </SyntaxHighlighter>
                </span>
                has special meaning to the shell and must be quoted if it is to
                represent itself
              </p>
              <h2 className={styles.subheading}># Comments</h2>
              <p className={styles.subheaddes}>
                {" "}
                In a shell a word beginning with '#' causes that word and all
                remaining characters on that line to be ignored.
              </p>
            </div>
            <div className={styles.section}>
              <h1 className={styles.headingblog}>Shell Commands</h1>
              <h2 className={styles.subheading}> # Reserved Words</h2>
              <p className={styles.subheaddes}>
                {" "}
                - These are words that have special meaning to the shell.
                Following words are recognized as reserved when unquoted and the
                first word of a command
              </p>
              <p className={styles.subheaddes}>
                <SyntaxHighlighter>
                  - [ if | then | elif | else | fi | time | for | in | until |
                  while | do | done | case | esac | coproc | select | function |
                  &#123; &#8739; &#125; | [[ | ]] | ! ]
                </SyntaxHighlighter>
              </p>
              <p className={styles.subheaddes}>
                - **in** and **do** are recognized as reserved words if they are
                the third word in a **for** command
              </p>

              <h2 className={styles.subheading}> # Simple Commands</h2>
              <p className={styles.subheaddes}>
                First word generally specifies a command to be executed, with
                the rest of the words being that command arguments.
              </p>

              <h2 className={styles.subheading}># Pipeines</h2>
              <SyntaxHighlighter>
                - [time [-p]] [!] command1 [ | or |& command2 ]
              </SyntaxHighlighter>
              <p className={styles.subheaddes}>
                The output of each command in the pipeline is connected via a
                pipe to the input of the next command
              </p>
              <p className={styles.subheaddes}>
                If '|&' is used command1's standard error is connected to
                command2's standard input
              </p>
            </div>
            <div className={styles.section}>
              <h2 className={styles.subheading}> # Lists Of Commands</h2>
              <p className={styles.subheaddes}>
                - A list is a sequence of one or more pipelines seperated by one
                of the operators ';', '&', '&&'[AND], '||'[OR]
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Bash;
